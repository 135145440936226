<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/information"
          >-<span v-html="'&nbsp'"></span>资讯<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span>中央加快住房租赁市场立法</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsDate">
                <div class="year">2020-12</div>
                <div class="day">20</div>
              </div>
              <div class="newsText">中央加快住房租赁市场立法</div>
            </div>
            <div class="news_content">
              <p>中央加快住房租赁市场立法 住房结构将进一步改善</p>
              <p>
                2月16日正式闭幕的中央经济工作会议，对中国房地产市场提出了新的定位和工作部署，“房子是用来住的，不是用来炒的”这一表述，被看作是在减弱住房金融属性。同时，会议在抑制房地产市场泡沫、增加土地供应、落实人地挂钩等方面的部署，也决定着明年楼市调控的基调和市场走势。
              </p>
              <p>“房子是用来住的，不是用来炒的”</p>
              <p>——未来或将严防金融资金炒房</p>
              <p>
                “房子是用来住的，不是用来炒的”，在部署2017年中国房地产市场工作时，中央经济工作会议率先明确了这一战略性定位。
              </p>
              <p>
                看似通俗易懂的表述背后，有着怎样的含义？中国社科院城市与竞争力研究中心主任倪鹏飞告诉中新网记者，上述定位意在减弱金融属性，住房属于耐用消费品，基本功能是居住，不是用于投资和投机赚钱。
              </p>
              <p>
                倪鹏飞说，未来金融监管层面的关键，就是要防止股市、债市、保险和银行资金违规进入楼市。“此轮楼市过热，最主要原因就是金融机构利用监管漏洞，采取不同形式让资金绕道转入房地产市场”。
              </p>
              <p>
                “炒房最可怕的地方不在于用自有资金炒房，而在于用金融资金炒房，金融机构参与是造成高杠杆的主要因素。”中国社科院城市发展与环境研究中心原主任、中国城市经济学会副会长牛凤瑞在接受记者采访时坦言。
              </p>
              <p>
                作为从业人士，易居研究院智库中心研究总监严跃进解释称，“政策含义非常明确，住房需求要继续得到保障，炒房需求要管控和遏制”。
              </p>
              <p>
                为保障正常的购房和贷款需求，会议提出，要在宏观上管住货币，微观信贷政策要支持合理自住购房，严格限制信贷流向投资投机性购房。
              </p>
              <p>抑制房地产泡沫、防止大起大落</p>
              <p>——要坚持分类调控，因城因地施策</p>
              <p>
                会议要求，综合运用金融、土地、财税、投资、立法等手段，加快研究建立符合国情、适应市场规律的基础性制度和长效机制，既抑制房地产泡沫，又防止出现大起大落。
              </p>
              <p>
                “可以看出，政策明确了明年房地产调控的总体思路。”严跃进告诉记者，一是抑制房地产泡沫，主要是土地市场和房价的泡沫；二是防范市场出现大起大落，让市场更加稳定，从而预期才会更加稳定。”
              </p>
              <p>
                挤出炒房泡沫后，真实的供求关系才更容易显现。倪鹏飞分析，决策层清醒认识到房地产的定位，绝不容忍投机的泛滥，如果采取措施确保住房回归居住和消费属性，有助于房地产市场挤出泡沫，显现真实的供求关系，有利于房地产市场健康平稳发展。
              </p>
              <p>
                会议明确，去库存方面，要坚持分类调控，因城因地施策，重点解决三四线城市房地产库存过多问题。要把去库存和促进人口城镇化结合起来，提高三四线城市和特大城市间基础设施的互联互通，提高三四线城市教育、医疗等公共服务水平，增强对农业转移人口的吸引力。
              </p>
              <p>落实人地挂钩政策</p>
              <p>——一、二线城市或加大土地供应指标</p>
              <p>
                会议指出，要落实人地挂钩政策，根据人口流动情况分配建设用地指标。要落实地方政府主体责任，房价上涨压力大的城市要合理增加土地供应，提高住宅用地比例，盘活城市闲置和低效用地。特大城市要加快疏解部分城市功能，带动周边中小城市发展。
              </p>
              <p>
                倪鹏飞认为要区别看待：三、四线城市的情况是地方政府大量供应土地，由于供应总量多，房价反而会低；但在一、二线城市，由于主、客观两个层面的作用造成土地供应不足，形成土地的饥渴和短缺，加上土地拍卖制度就把地价搞得特别高。
              </p>
              <p>
                “针对一、二线土地市场供应不足导致的地价、房价上涨，可以从两个层面来解决。”倪鹏飞建议，首先要加大土地供应指标，采取人地挂钩模式；其次由于一、二线城市存量土地比较多，同样有盘活的余地。
              </p>
              <p>
                严跃进说，“政策初衷，就是考虑到各大城市人口流动的差异性特征”，预计明年继续实行限购的城市，在获得开发用地、土地转性等方面将有更多机会，2017年下半年楼市供应紧张局面有望改变。
              </p>
              <p>加快住房租赁市场立法</p>
              <p>——城市住房结构将进一步改善</p>
              <p>
                会议还指出，要加快住房租赁市场立法，加快机构化、规模化租赁企业发展。加强住房市场监管和整顿，规范开发、销售、中介等行为。
              </p>
              <p>
                今年6月份，国务院办公厅发布《关于加快培育和发展住房租赁市场的若干意见》提出，到2020年，基本形成供应主体多元、经营服务规范、租赁关系稳定的住房租赁市场体系，基本形成保基本、促公平、可持续的公共租赁住房保障体系。为加快培育和发展住房租赁市场，鼓励房地产开发企业开展住房租赁业务，允许将商业用房等按规定改建为租赁住房。
              </p>
              <p>
                在严跃进看来，发展住房租赁市场无论是从迎合城市各层次住房者的租赁需求；还是进一步改善城市住房结构的角度，都有益处。
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/help1">
                  <img src="../../assets/images/help/help1.jpeg" alt="" />
                  <div class="msg">
                    房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/help2">
                  <img src="../../assets/images/help/help3.jpeg" alt="" />
                  <div class="msg">租房也可变Dream House</div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help3">
                  <img src="../../assets/images/help/help2.png" alt="" />
                  <div class="msg">
                    房东利益如何最大化之合理装修
                  </div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help4">
                  <img src="../../assets/images/help/help4.png" alt="" />
                  <div class="msg">自建房的房屋租赁税计算</div></router-link
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/information/资讯背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
          .newsDate {
            float: left;
            width: 55px;
            height: 55px;
            background-color: #436993;
            color: #fff;
            padding: 6px 2px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            .year {
              font-size: 12px;
            }
            .day {
              font-size: 28px;
            }
          }
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          padding-left: 70px;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
